<template>
  <PrimaryLayout :breadcrumbs="breadcrumbs">
    <Balances 
      :available="fullBalance" 
      :secondLabel="secondBalanceLabel" 
      :secondAmount="secondBalanceAmount" 
      :numSelected="selectedInvoices.length"
      :highlightSecondAmount="!hasEnoughFunds"
      @clearSelected="clearSelected"
    >      
      <div class="controls">
        <BaseSearchInput
          :value="filterValue"
          @update:value="handleFilterChange"
        />
        <BaseButton 
          class="pay-selected"
          @click="openConfirmPaySelectedModal" 
          :isDisabled="!areAnyInvoicesSelected || !hasEnoughFunds"
        >
          Pay Selected
        </BaseButton>
      </div>
    </Balances>

    <BaseTable
      v-slot="{ items }"
      :is-loading="invoicesAreLoading"
      :headers="headers"
      initial-sort-by="date"
      :items="invoices"
      :empty-message-translation-key="'invoices.empty_state'"
    >
      <InvoicesTableRow
        v-for="item in items"
        :key="item.id"
        :invoice="item"
        :isSelected="isSelected(item.id)"
        @update:checked="(value) => setInvoiceSelectionStatus(item.id, value)"
      /> 
    </BaseTable>
  </PrimaryLayout>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import BaseSearchInput from '@uicommon/components/BaseSearchInput'
import BaseTable from '@uicommon/components/BaseTable'
import PrimaryLayout from 'components/PrimaryLayout'
import InvoicesTableRow from 'components/InvoicesTableRow'
import Balances from 'components/Balances'
import { useHolofuelStore, useUiStore, useHhaStore } from 'src/stores'
import BaseButton from '@uicommon/components/BaseButton.vue'

const headers = [
  // attn: an additional header is added in the computed function
  {
    key: 'happName',
    label: 'hApp',
    isVisibleOnMobile: true,
    isSortable: true
  },
  {
    key: 'date',
    label: 'Date Received',
    isVisibleOnMobile: false,
    isSortable: true
  },
  {
    key: 'id',
    label: 'Transaction Hash',
    isVisibleOnMobile: false,
    isSortable: false
  },
  {
    key: 'amount',
    label: 'Amount',
    isVisibleOnMobile: true,
    isSortable: true,
    align: 'end'
  },
  {
    key: 'status',
    label: 'Payment Status',
    isVisibleOnMobile: true,
    isSortable: true
  },
  {
    key: 'jurisdiction',
    label: 'Country',
    isVisibleOnMobile: true,
    isSortable: true
  }
]

export default {
  name: 'BillingAndPaymentInvoices',
  components: {
    PrimaryLayout,
    BaseSearchInput,
    BaseTable,
    InvoicesTableRow,
    Balances,
    BaseButton
},
  data () {
    return {
      breadcrumbs: [
        {
          label: 'Billing & Payments',
          path: '/billing-and-payments'
        },
        {
          label: 'Invoices'
        }
      ],
      filterValue: '',      
    }
  },
  methods: {
    ...mapActions(useUiStore, ['setInvoiceSelectionStatus', 'openConfirmPaySelectedModal']),
    handleFilterChange (val) {
      this.filterValue = val
    },
    isSelected (invoiceId) {
      return !!this.selectedInvoiceIds[invoiceId]
    },
    handleSelectAll (isSelected) {
      for (let i = 0; i < this.invoices.length; i++) {
        if (this.invoices[i].status === "Unpaid") {
          this.setInvoiceSelectionStatus(this.invoices[i].id, isSelected)          
        }
      }
    },
    clearSelected () {
      this.handleSelectAll(false)
    }
  },  
  computed: {
    ...mapState(useHolofuelStore, ['actionableHostingInvoices', 'acceptedHostingInvoices', 'completedHostingInvoices', 'invoicesAreLoading', 'fullBalance', 'fullBalanceNum']),
    ...mapState(useUiStore, ['selectedInvoiceIds']),
    ...mapState(useHhaStore, ['addJurisdictionInfoToInvoice']),
    headers () {
      return [{
        key: 'selectAll',
        checkBoxFunction: value => this.handleSelectAll(value),
        checkBoxValue: this.areAllVisibleInvoicesSelected,
        isVisibleOnMobile: true,
        isSortable: false
      }].concat(headers)
    },
    invoices () {
      return this.actionableHostingInvoices
        .concat(this.acceptedHostingInvoices)
        .concat(this.completedHostingInvoices)
        .map(this.addJurisdictionInfoToInvoice)
        .filter(invoice => {
        if (!this.filterValue) return true
        
        const downcaseFilterValue = this.filterValue.toLowerCase()

        return invoice.id.toLowerCase().includes(downcaseFilterValue)
        || invoice.date.toLowerCase().includes(downcaseFilterValue)
        || invoice.happName.toLowerCase().includes(downcaseFilterValue)
        || invoice.status.toLowerCase().includes(downcaseFilterValue)
        || invoice.amount.includes(downcaseFilterValue)
        || invoice.formattedAmount.includes(downcaseFilterValue)      
      })
    },
    selectedInvoices () {
      return this.actionableHostingInvoices.filter(invoice => this.isSelected(invoice.id))
    },
    areAllVisibleInvoicesSelected () {
      for (let i = 0; i < this.invoices.length; i++) {
        if (this.invoices[i].status === 'Unpaid' && !this.isSelected(this.invoices[i].id)) {
          return false
        }
      }
      return true
    },
    areAnyInvoicesSelected () {
      return this.selectedInvoices.length > 0
    },
    totalOwed () {
      return String(this.actionableHostingInvoices.map(invoice => invoice.amount).reduce((total, invoiceAmount) => total + Number(invoiceAmount), 0))
    },
    selectedOwed () {
      return String(this.selectedInvoices.map(invoice => invoice.amount).reduce((total, invoiceAmount) => total + Number(invoiceAmount), 0))
    },
    secondBalanceLabel () {
      return this.areAnyInvoicesSelected ? 'Selected Invoices' : 'Total Owed'
    },
    secondBalanceAmount () {
      return this.areAnyInvoicesSelected ? this.selectedOwed : this.totalOwed
    },
    hasEnoughFunds () {          
      return !this.areAnyInvoicesSelected || Number(this.secondBalanceAmount) <= this.fullBalanceNum
    }
  }
}

</script>

<style scoped>
.controls {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: 52px;
}
.pay-selected {
  font-size: 12px;
  padding: 5px 10px;
  margin-left: 24px;
}
</style>
