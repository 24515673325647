<template>
  <div class="row">
    <BellIcon :type="bellType" />
    <div class="column">
      <h3 @click="handleCardClick" class="title">{{ title }}</h3>
      <div v-if="notificationInfo.overdue" @click="handleCardClick" class="body">
        You have {{ invoiceNounPhrase }} overdue. Please pay now to avoid your hApps being unhosted. Total amount to be paid is {{ totalAmount }} HF.
      </div>
      <div v-else @click="handleCardClick" class="body">
        You have {{ invoiceNounPhrase }} due soon. Total amount to be paid is {{ totalAmount }} HF. These invoices {{ dueDatePhrase }}.
      </div>
      <div class="buttons">
        <BaseButton @click="handleDismiss" :type="EButtonType.secondary" class="dismiss-button">Dismiss</BaseButton>
        <BaseButton @click="handlePayInvoices" class="pay-button">Proceed to Pay</BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapWritableState, mapState } from 'pinia'
import { useUiStore, useNotificationStore } from 'src/stores'

import BaseButton from '@uicommon/components/BaseButton.vue'
import BellIcon from '@uicommon/components/icons/BellIcon'
import { EButtonType } from '@uicommon/types/ui'
import { presentHolofuelAmount, pluralizedAmount } from 'src/utils'
 
export default {
  name: 'NotificationToast',
  components: {
    BellIcon,
    BaseButton
  },
  props: {
    closeParent: {
      type: Function,
      required: true
    },
    notificationInfo: {
      type: Object,
      required: true
    },
    handleCardClick: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['dismissNotification']),
    ...mapActions(useUiStore, ['openConfirmPaySelectedModal', 'setInvoiceSelectionStatus']),
    handlePayInvoices () {
      const invoices = this.notifications[this.notificationInfo.id].invoices

      this.clearInvoiceSelection()
      for (let i = 0; i < invoices?.length; i ++) {
        this.setInvoiceSelectionStatus(invoices[i].id, true)
      }
      this.closeParent()
      this.openConfirmPaySelectedModal()
    },
    handleDismiss () {
      this.dismissNotification(this.notificationInfo.id)
    }
  },
  computed: {
    ...mapState(useNotificationStore, ['notifications']),
    ...mapWritableState(useUiStore, ['selectedInvoiceIds', 'clearInvoiceSelection']),
    title () {
      return this.notificationInfo.overdue ? 'Invoices Overdue' : 'Invoices Due Soon'
    },
    bellType () {
      return this.notificationInfo.overdue ? 'halo-overdue' : 'halo'
    },
    EButtonType () {
      return EButtonType
    },
    invoiceNounPhrase () {      
      return pluralizedAmount(this.notificationInfo.numInvoices, 'invoice')
    },
    totalAmount () {
      return presentHolofuelAmount(String(this.notificationInfo.totalAmount))
    },
    dueDatePhrase () {
      const now = new Date()
      if (this.notificationInfo.dueDate.isSame(now, "day")) {
        return 'are due today'
      } else if (this.notificationInfo.dueDate.isBefore(now)) {
        return `were due on ${this.notificationInfo.dueDate.format('D MMMM YYYY')}`
      } else {
        return `are due on ${this.notificationInfo.dueDate.format('D MMMM YYYY')}`
      }
    },
    invoiceState () {
      return this.notificationInfo.overdue ? 'overdue' : 'due soon'
    }
  }
}
</script>

<style scoped>
.toast {
  position: fixed;
  top: 102px;
  right: 62px;
  width: 368px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid black;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 12px 16px -4px #1018281A;
}
.row {
  display: flex;
}
.column {
  margin: 0 12px;
  max-width: 304px;
}
.title {
  margin: 8px 0 0 0;
  font-size: 14px;
  font-weight: 700;
  color: #313C59;
}
.body {
  margin-top: 6px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;  
  color: #606C8B;
}
.buttons {
  margin-top: 22px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.dismiss-button {
  padding: 8px 14px;
  margin-right: 12px;
}
.pay-button {
  padding: 8px 14px;  
}
.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}
 
</style>
