import { lookupTxt, wellknown } from 'dns-query'

export const presentFullHolofuelAmount = amountString => {
  if (!amountString) {
    return '--'
  }
  const { integerPart, decimalPart } = parseHolofuelAmount(amountString)
  return insertCommas(integerPart) + decimalPart
}

export const presentHolofuelAmount = amountString => {
  if (!amountString) {
    return '--'
  }
  const { integerPart, decimalPart } = parseHolofuelAmount(amountString)
  return insertCommas(integerPart) + decimalPart.slice(0, 5)
}

const parseHolofuelAmount = amountString => {
  const dotIndex = amountString.search(/\./)
  const integerPart = amountString.slice(0, dotIndex === -1 ? amountString.length : dotIndex)
  const decimalPart = amountString.slice(integerPart.length)
  return { integerPart, decimalPart }
}

const insertCommas = numberString => {
  let result = ''
  let i = numberString.length
  while (/\d{4}/.test(numberString.slice(i - 4, i))) {
    result = `,${numberString.slice(i - 3, i)}${result}`
    i -= 3
  }
  return numberString.slice(0, i) + result
}

export const presentBytes = bytes => {
  if (bytes === null || isNaN(bytes)) {
    return '-- GB'
  }

  if (bytes === 0) {
    return '0 GB'
  }

  const k = 1024;
  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB'];

  const i = Math.min(Math.floor(Math.log(bytes) / Math.log(k)), 4);

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export const presentMicroSeconds = ms => {
  if (ms === null || isNaN(ms)) {
    return '-- ms'
  }

  if (ms === 0) {
    return '0 ms'
  }

  const k = 1000;
  const sizes = ['μs', 'ms', 's'];

  const i = Math.min(Math.floor(Math.log(ms) / Math.log(k)), 2);

  return parseFloat((ms / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export const presentTailOfHash = hash => `...${hash.slice(-5)}`

// for use in manual testing
export const randomHash = () => new Uint8Array([... new Array(39)].map(() => Math.floor(Math.random() * 256)))

// This currently doesn't need to handle weird words, but if we're ever counting sheep, we can handle it here
export const pluralize = (string, count = 2) =>
  (count > 1)
  ? `${string}s`
  : string

export const pluralizedAmount = (count, string) => `${count} ${pluralize(string, count)}`

export const wait = ms =>
  new Promise(resolve => setTimeout(resolve, ms))

export const isValidUrl = string => {

  // Additional check for intra string spaces, as the URL treats strings like "http://test.com         /" as valid
  if (string.trim().includes(" ")) {
    return false
  }

  let url
  
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const stripTrailingSlash = url => {
  return url.replace(/\/$/, "");
}

export const stripProtocol = url => {
  return url.replace(/^https?\:\/\//i, "");
}

export const ensureProtocol = url => {
  if (url.match(/^https?\:\/\//i)) {
    return url
  } else {
    return 'https://' + url
  }
}

export const presentHostedUrl = hostedUrl => {
  let trimmed = hostedUrl && hostedUrl.trim()
  if (!trimmed) return null

  return stripTrailingSlash(stripProtocol(trimmed))
}

export const kDontShowGoToHoloFuelModalAgainLSKey = "dont-show-confirm-link-to-holofuel"

export const JURISDICTION_STRATEGY_ENUM = {
  NOT_SELECTED: 0,
  EXCLUSIONS: 1,
  INCLUSIONS: 2
}

export const verifyDnsTxtRecord = async (url, expectedValue) => {
  // console.log(`verifyDnsTxtRecord url: ${url} expectedvalue: ${expectedValue}`)
  if( !url || !expectedValue ) {
    return false
  }
  
  try {
    const url_object = new URL(url)
    const domain = url_object.hostname
  
    const result = await lookupTxt(domain, { endpoints: wellknown.endpoints() })
    
    if( result && result.entries && result.entries.length > 0) {
      // console.log(`verifyDnsTxtRecord`, result.entries)
      return result.entries.some(txt_entry => txt_entry.data === expectedValue);
    } else {
      console.log(`verifyDnsTxtRecord didn't find any entries for ${domain}`, result)
    }
  } catch (err) {
    console.log(`verifyDnsTxtRecord error`, err)
  }

  
  return false
}

export const kTxtPrefix = "holo-verification"