<script setup>
import ArrowInSquareIcon from '@uicommon/components/icons/ArrowInSquareIcon.vue'
import BangIcon from '@uicommon/components/icons/BangIcon.vue'
import BaseChip from '@uicommon/components/BaseChip.vue'
import ChainIcon from '@uicommon/components/icons/ChainIcon.vue'
import ClockIcon from '@uicommon/components/icons/ClockIcon.vue'
import CopyIcon from '@uicommon/components/icons/CopyIcon'
import InvoiceIcon from '@uicommon/components/icons/InvoiceIcon.vue'
import LeftChevronIcon from '@uicommon/components/icons/LeftChevronIcon.vue'
import InvisibleEyeIcon from '@uicommon/components/icons/InvisibleEyeIcon.vue'
import VisibleEyeIcon from '@uicommon/components/icons/VisibleEyeIcon.vue'

import { EChipType } from '@uicommon/types/ui'
import HAppImage from '@uicommon/components/HAppImage.vue'

import PanelLink from 'components/PanelLink.vue'
import PencilIcon from '@uicommon/components/icons/PencilIcon.vue'
import PrimaryLayout from 'components/PrimaryLayout.vue'
import { useRouter } from 'vue-router'

import { useHhaStore } from 'src/stores'
import { presentHolofuelAmount, presentMicroSeconds, presentBytes, ensureProtocol, kTxtPrefix, verifyDnsTxtRecord } from 'src/utils'
import { copyToClipboard } from '@uicommon/utils/clipboardUtils'
import { ref, computed, onMounted } from 'vue'

const router = useRouter()

const props = defineProps({
  id: {
    type: String,
    required: true
  }})

const hhaStore = useHhaStore()
const isError = ref(false)

const happ = ref(null)
const urlValidated = ref(true)
const showTXTValue = ref(false)

onMounted(() => {
  happ.value = hhaStore.happ(props.id)
  urlValidate()
})

const happUsageStats = ref({
  cost: null, // '476005.3028',
  usage: null, // 9_099_000_000  ,
  bandwidth: null, // 238_000_000_000,
  uniqueVisitors: null, // 34,
  totalSourceChains: null // 712
})

const isLoading = computed(() => {
  return false // Don't need this yet but will use it when we load stats from new service
})

const getChipProps = computed( () => {
  if( happ?.value?.is_draft ) {
    return { label: 'Draft', type: EChipType.warning }
  } else if( happ?.value?.is_paused ) {
    return { label: 'Paused', type: EChipType.danger }
  } else {
    return { label: 'Deployed', type: EChipType.success }
  }
})

const domain = computed(() => {
  if( happ?.value?.hosted_urls?.[0] ) {
    const url_object = new URL(ensureProtocol(happ?.value?.hosted_urls?.[0]))
    return url_object.hostname
  } else {
    return ""
  }
})

const urlValidate = async() => {
  if( !happ?.value?.hosted_urls?.[0]) {
    return true
  } else {
    const txt_record_validator = `${kTxtPrefix}=${happ.value.id}`
    urlValidated.value = await verifyDnsTxtRecord(ensureProtocol(happ?.value?.hosted_urls?.[0]), txt_record_validator)
  }
}

const presentNullableValue = (value) => {
  return value ?? '--'
}

const presentEditedDate = (timestamp) => {
  if (!timestamp) return '--'

  const date = new Date(Math.floor(Number(timestamp) / 1_000));
  const formattedDate = date.toLocaleDateString('en-GB', {
    day: 'numeric', month: 'short', year: 'numeric'
  });

  return formattedDate
}

const editHapp = (id) => {
  router.push({ path: `/happ/${id}`})
}

const handlePanelClick = () => {
  router.push({ name: 'Billing&PaymentInvoices'})
}

const onCopyTXTRecordToClipboard = () => {
  copyToClipboard(`${kTxtPrefix}=${happ.value.id}`)
}

</script>

<template>
    <PrimaryLayout
        :title="'hApp Detail'"
        class="happs"
        :is-content-loading="isLoading"
        :is-content-error="isError"
    >
        <div class="happ-details">
            <router-link
                class="back-link"
                to="/happs"
            >
                <LeftChevronIcon class="left-chevron" />
                Back
            </router-link>
            <div class="columns">
                <div class="left-column desktop">
                    <HAppImage
                        :happ="happ ? happ || happ : {logo_url: null}"
                        size="178px"
                        class="happ-image"
                    />
                    <div class="description-label">
                      Description
                    </div>
                    <div class="description">
                      {{ happ?.description }}
                    </div>
                </div>
                <div class="main-column">
                  <div class="info-row">
                    <h2 class="name desktop">
                      {{ happ?.name }}
                    </h2>
                    <BaseChip
                      :label="getChipProps.label"
                      :type="getChipProps.type"
                    />
                    <PencilIcon
                      class='pencil-icon'
                      @click="editHapp(props.id)"
                      :size="15"
                    />
                  </div>
                  <div class="info-row">
                    Domain:<span class="info">&nbsp;{{ domain }}</span>
                    <BaseChip
                      v-if="happ?.hosted_urls?.[0]"
                      :label="urlValidated ? 'Verified' : 'Unverified'"
                      :type="urlValidated ? EChipType.success : EChipType.danger"
                    />
                  </div>
                  <div class="info-row" v-if="!urlValidated">
                    <div class="unverified-domain-container">
                      <p class="dns-step-label">To verify domain ownership:</p>
                      <p class="dns-step-label">Step 1</p>
                      <p class="dns-step">Open a new tab or window in your browser and sign into the website where your domain is registered.</p>
                      <p class="dns-step-label">Step 2</p>
                      <p class="dns-step">Find where you manage your DNS settings (e.g. DNS Management, Control Panel, cPanel, etc.)</p>
                      <p class="dns-step-label">Step 3</p>
                      <p class="dns-step">Add a new TXT record for the domain or sub-domain, paste in the TXT Value below, and save. </p>
                      <p class="dns-info">Verification can take up to 30 minutes. If it takes longer, check that you have input the TXT Value correctly.</p>
                    </div>
                  </div>
                  <div class="info-row">
                    Last Edited Date:<span class="info">&nbsp;{{ presentEditedDate(happ?.last_edited) }}</span>
                  </div>
                  <div class="info-row publish-margin">
                    TXT Value:
                    <span class="info txt-content" v-if="showTXTValue">&nbsp;{{ kTxtPrefix }}={{ happ?.id }}</span>
                    <span class="info txt-content" v-else>&nbsp;****************</span>
                    <span class="txt-content" v-if="showTXTValue">&nbsp;<InvisibleEyeIcon class="pencil-icon" @click="showTXTValue=!showTXTValue"/></span>
                    <span class="txt-content" v-else>&nbsp;<VisibleEyeIcon class="pencil-icon" @click="showTXTValue=!showTXTValue"/></span>
                    <span class="txt-content">&nbsp;<CopyIcon class="pencil-icon" @click="onCopyTXTRecordToClipboard" /></span>
                  </div>
                  <div class="info-row">
                    7 day snapshot:
                  </div>
                  <div class="info-row">
                    Cost:<span class="info">&nbsp;{{ presentHolofuelAmount(happUsageStats?.cost) }} HF</span>
                  </div>
                  <div class="info-row usage-margin">
                    Usage:<span class="info">&nbsp;{{ presentMicroSeconds(happUsageStats?.usage) }}</span>CPU
                    <span class="info cpu-margin">&nbsp;{{ presentBytes(happUsageStats?.bandwidth) }}</span>Data Transfer
                  </div>
                  <div class="info-row">
                    <ClockIcon class="clock-icon" /> Total unique visitors:&nbsp;&nbsp;<span class="info">{{ presentNullableValue(happUsageStats?.uniqueVisitors) }}</span>
                  </div>
                  <div class="info-row">
                    <ChainIcon class="chain-icon" /> Total source chains:&nbsp;&nbsp;<span class="info">{{ presentNullableValue(happUsageStats?.totalSourceChains) }}</span>
                  </div>
                  <div class="grayed-out go-to-service-logs-link-container" @click="handleGoToServiceLogs">
                    <ArrowInSquareIcon class="arrow-in-square-icon" /><span>Download service logs</span>
                  </div>                                    
                </div>
                <div class="right-column desktop">
                  <PanelLink class="panel-link" :panelText="'Invoices'"  @click="handlePanelClick">
                    <InvoiceIcon />
                  </PanelLink>
                  <PanelLink class="panel-link" :panelText="'Exceptions'" :disabled="true" :showBadge="true" :badgeText="'2 new'">
                    <BangIcon :disabled="true" />
                  </PanelLink>
                </div>
            </div>
        </div>

    </PrimaryLayout>
</template>

<style lang="scss" scoped>
.happ-details {
  display: flex;
  flex-direction: column;
  background-color: var(--white-color);
  box-shadow: 0 4px 20px #eceef1;
  border-radius: 5px;
  margin: 0 10px 20px 12px;
  padding: 30px;
  color: var(--grey-color);
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
}
.back-link {
  color: var(--grey-color);
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 32px;
}
.columns {
  display: flex;
}
.left-column {
  display: flex;
  flex: 0;
  flex-direction: column;
  margin-right: 32px;
  min-width: 190px;
}
.happ-image {
  margin-bottom: 34px;
}
.description-label {
  margin-bottom: 4px;
}
.description {
  font-weight: 700;
  color: var(--grey-dark-color);
}
.main-column {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.right-column {
  display: flex;
  flex: 0;
  flex-direction: column;
  min-width: 240px;
}
.mobile-column {
  display: none;
  align-items: center;
  flex-direction: column;
}

.dns-step-label {
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  margin: 8px 0px 4px 0px
}

.dns-step {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
  margin: 0px 0px 8px 0px;
}

.dns-info {
font-size: 12px;
font-weight: 700;
line-height: 140%; /* 16.8px */  
}

.name {
  margin: 0 0 0 0;
  color: var(--grey-dark-color);
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
}
.info-row {
  display: flex;
  align-items: center;
  color: var(--grey-color);
  margin-bottom: 14px;
  font-size: 14px;
}

.unverified-domain-container {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: #EDF1FF;

    display: inline-flex;
    padding: 12px;
    gap: 10px;

    color: #606C8B;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
}

.large-text {
  font-size: 16px;
}
.info {
  font-weight: 700;
  color: var(--grey-dark-color);
  margin-right: 8px;
}
.publish-margin {
  margin-bottom: 48px;
}
.usage-margin {
  margin-bottom: 48px;
}
.cpu-margin {
  margin-left: 8px;
}
.clock-icon {
  margin-left: 2px;
  margin-right: 10px;
}
.chain-icon {
  margin-right: 8px;
}
.arrow-in-square-icon {
  margin-right: 4px;
  margin-left: 4px;
}

.pencil-icon {
  margin-left: 8px;
  cursor: pointer;
  opacity: 0.5;
}
.go-to-service-logs-link-container {
  align-items: center;

  display: flex;
  cursor: pointer;
}

.txt-content {
    padding-top: 2px;
}

.go-to-service-logs-link-container span {
  color: var(--content-color);
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}

/* Temporary, remove once we have all live data */
.grayed-out {
  color: rgba(96, 108, 139, 0.18);
}
.grayed-out div {
  color: rgba(96, 108, 139, 0.18);
}
.grayed-out span {
  color: rgba(96, 108, 139, 0.18);
}

.panel-link {
  width: 100%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1050px) {
  .happ-details {
    margin: 0 0 20px 0;
    padding: 18px;
  }
  .mobile-column {
    display: flex;
  }
  .main-column {
    margin: 0 4px;
  }
  .back-link {
    margin-bottom: 4px;
  }
  .desktop {
    display: none;
  }
  .happ-image {
    margin-bottom: 10px;
  }
  .name {
    margin-bottom: 8px;
  }
  .description {
    font-size: 11px;
    margin-bottom: 46px;
  }
  .info-row {
    font-size: 14px;
  }
  .usage-row {
    flex-direction: column;
    margin-bottom: 24px;
  }
  .usage-label {
    margin-bottom: 10px;
  }
  .usage {
    margin: 0 0 10px 0;
  }
}
</style>
