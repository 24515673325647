<template>
  <PrimaryLayout title="hApps">
    <div v-if="getMyHappsIsLoading" class='loader-wrapper form'>
      <Loader />
    </div>
    <div v-else class='form'>
      <h2>{{ pageTitle }} <div v-if="happStatus" :class="['happ-status', `${happStatus}-status`]">{{ happStatus }}</div></h2>

      <h3>hApp details</h3>

      <div class='happ-details-list'>
        <div class='item row'><span class='detail-label'>1. hApp manager name:</span> {{ happManagerName }}</div>
        <div class='item column happ-setup'>
          <span class='detail-label row'>2. Configuration and set up<SmallQuestionIcon class='question-icon' /></span>
          <div v-if="existingHapp" class="item-content column">

            <label for='happFile'>Link to hApp file</label>
            {{ happFile }}

            <label for='uid'>Network seed (optional)</label><!-- Need to add a SmallQuestionIcon -->
            {{ uid || "none" }}

            <label for='hostedUrl'>URL of hosted UI (optional)</label><!-- Need to add a SmallQuestionIcon -->
            <input
              type="text"
              v-model="hostedUrl"
              id='hostedUrl'
              :disabled="existingHapp?.is_paused"
              :class="{error: errors.hostedUrl}"
              placeholder="Enter the URL where you are hosting your web UI"
            />
            <div v-if="errors.hostedUrl ==='invalid'" class='error-row'>
              <SmallCircledExclamationIcon /> {{ hostedUrlErrorMessage }}
            </div>
            <div v-if="errors.hostedUrl ==='duplicate'" class='error-row'>
              <SmallCircledExclamationIcon /> {{ hostedUrlNotUniqueErrorMessage }}
            </div>            

          </div>
          <div v-else class='item-content column'>

            <label for='happFile'>Link to hApp file</label>
            <input
              type="text"
              v-model="happFile"
              id='happFile'
              :class="{error: errors.happFile}"
              placeholder="Must end in .happ"
            />
            <div v-if="errors.happFile" class='error-row'>
              <SmallCircledExclamationIcon /> {{ happFileErrorMessage }}
            </div>

            <label for='uid'>Network seed (optional)</label><!-- Need to add a SmallQuestionIcon -->
            <input
              type="text"
              v-model="uid"
              id='uid'
              :class="{error: errors.uid}"
              placeholder="Access key for your hApp's private network"
            />

            <label for='hostedUrl'>URL of hosted UI (optional)</label><!-- Need to add a SmallQuestionIcon -->
            <input
              type="text"
              v-model="hostedUrl"
              id='hostedUrl'
              :class="{error: errors.hostedUrl}"
              placeholder="URL of your web UI"
            />
            <div v-if="errors.hostedUrl ==='invalid'" class='error-row'>
              <SmallCircledExclamationIcon /> {{ hostedUrlErrorMessage }}
            </div>
            <div v-if="errors.hostedUrl ==='duplicate'" class='error-row'>
              <SmallCircledExclamationIcon /> {{ hostedUrlNotUniqueErrorMessage }}
            </div>            

          </div>
        </div>
        <div class='item column happ-display-section'>
          <span class='detail-label row'>3. hApp display</span>
          <div class='item-content column'>
            <div class='happ-display row'>
              <label for='name' class='happ-display-label'>hApp name:</label>
              <input
                type="text"
                v-model="name"
                id='name'
                :disabled="existingHapp?.is_paused"
                :class="{error: errors.name}"
                placeholder="Enter the name for your hApp"
              />
            </div>
            <div v-if="errors.name" class='error-row'>
              <SmallCircledExclamationIcon /> {{ nameErrorMessage }}
            </div>

            <div class='happ-display row'>
              <label for='description' class='happ-display-label'>Description:</label>
              <input
                type="text"
                v-model="description"
                id='description'
                :disabled="existingHapp?.is_paused"
                :class="{error: errors.description}"
                placeholder="Enter a description for your hApp"
              />
            </div>
            <div v-if="errors.description" class='error-row'>
              <SmallCircledExclamationIcon /> {{ descriptionErrorMessage }}
            </div>

            <div class='happ-display row'>
              <label for='logoUrl' class='happ-display-label'>Logo URL:</label>
              <input
                type="text"
                v-model="logoUrl"
                id='logoUrl'
                :disabled="existingHapp?.is_paused"
                :class="{error: errors.logoUrl}"
                placeholder="Enter the URL for your hApp logo"
              />
            </div>
            <div v-if="errors.logoUrl" class='error-row'>
              <SmallCircledExclamationIcon /> {{ logoUrlErrorMessage }}
            </div>

            <div v-if="logoUrl" class='happ-display row'>
              <img :src="logoUrl" class='logo-preview' />
            </div>
          </div>
        </div>
        <div class='item column'>
          <span class='detail-label row'>4. Login configuration<SmallQuestionIcon class='question-icon' /></span>
          <div class='item-content column'>
            <div class='login-configuration row'>
              <input
                type="checkbox"
                id="displayPublisherName"
                :disabled="existingHapp?.is_paused"
                v-model="displayPublisherName"
              />
              <label for="displayPublisherName">Display hApp manager on login screen</label>
            </div>
            <div class='login-configuration row'>
              <input type="checkbox"
                id="showRegistrationInfoUrl"
                :disabled="existingHapp?.is_paused"
                v-model="showRegistrationInfoUrl"
              />
              <label for="showRegistrationInfoUrl">Display "help" URL link next to Registration Code input field</label>
            </div>
            <input v-if="showRegistrationInfoUrl" type="text" v-model="registrationInfoUrl" id='registrationInfoUrl' :class="{error: errors.registrationInfoUrl}" placeholder="https://">
            <div v-if="errors.registrationInfoUrl" class='error-row'>
              <SmallCircledExclamationIcon /> {{ registrationInfoUrlErrorMessage }}
            </div>
          </div>
        </div>
      </div>

      <h3>Hosting details</h3>

      <div class='happ-details-list'>
        <div class='item column'>
          <span class='detail-label row'>1. Select categories & jurisdictions<SmallQuestionIcon class='question-icon' /></span>
          <div class='item-content column'>
            <div class='chip-row'>
              <label class='chip-row-label'>Categories:</label>
              <Chip class="chip-row-chips" :chips="categories.sort()"
                :availableChips="categoryList"
                :disabled="existingHapp?.is_paused"
                :placeHolderText="'Select categories'"
                :handleAddChip="addCategory"
                :handleRemoveChip="removeCategory"
              />
            </div>
            <div class='chip-row'>
              <label class='chip-row-label'>Jurisdictions:</label>
              <DropDown class="chip-row-dropdown"  label=""
                @optionChanged="onInclusionChange"
                :options="jurisdictionInclusionOptions"
                :disabled="existingHapp?.is_paused"
                :selectedOption="selectedJurisdictionInclusionOption"
              />
              <Chip class="chip-row-chips"
                :chips="(isJurisdictionStrategyExclude)
                  ? excluded_jurisdictions.sort()
                  : included_jurisdictions.sort()"
                :availableChips="jurisdictionList"
                :disabled="existingHapp?.is_paused"
                :placeHolderText="'Select jurisdictions'"
                :handleRemoveChip="removeJurisdiction"
                :handleAddChip="addJurisdiction"
              />
            </div>
            <div v-if="errors.includeOrExcludeJurisdiction" class='error-row'>
              <SmallCircledExclamationIcon /> {{ jurisdictionErrorMessage }}
            </div>
          </div>
        </div>
        <div class='item column'>
          <span class='detail-label row'>2. Configure pricing<SmallQuestionIcon class='question-icon' /></span>
          <div class='pricing-card'>
            <div class='slider-row row'>
              <span class='pricing-label'>Estimated unique users:</span>
              <span class='slider-label'>1</span>
              <Slider :value="uniqueUsers" @input="updateUniqueUsers" :disabled="existingHapp?.is_paused" :min="1" :max="20000" :logarithmic="true" />
              <span class='slider-label'>20,000</span>
            </div>
            <div class='slider-row row'>
              <span class='pricing-label'>Estimated daily active users:</span>
              <span class='slider-label'>1</span>
              <Slider :value="dailyUsers" @input="updateDailyUsers" :disabled="existingHapp?.is_paused" :min="1" :max="20000" :logarithmic="true" />
              <span class='slider-label'>20,000</span>
            </div>
            <table class='estimated-usage'>
              <tr><th /><th class='table-header'>Estimated monthly usage</th></tr>
              <tr>
                <td class='row-label'>CPU</td>
                <td> {{perMonth.cpu}}</td>
                <td class='unit'>mins</td>
              </tr>
              <tr class="hidden">
                <td class='row-label'>Storage</td>
                <td> {{perMonth.storage}}</td>
                <td class='unit'>GB</td>
              </tr>
              <tr>
                <td class='row-label'>Data Transfer</td>
                <td> {{perMonth.bandwidth}}</td>
                <td class='unit'>GB</td>
              </tr>
            </table>
          </div>
          <div class='pricing-card price-chooser'>
            <div class='column ranges-column'>
              <div class='pricing-label'>Choose prices using the slider for each option</div>
              <Ranges :numbers="rangesData" :value="priceSliderValue" :min="0" :max="maxRange" :numPartitions="20" class='ranges' />
              <div class='slider-wrapper row'>
                <Slider v-if="showCpuSlider" :value="cpuPrice" :disabled="existingHapp?.is_paused" @input="updateCpuPrice" :min="0" :max="Math.max(maxRange, cpuPrice)" :interval="maxRange / 100" />
                <Slider v-if="showStorageSlider" :value="storagePrice" :disabled="existingHapp?.is_paused" @input="updateStoragePrice" :min="0" :max="Math.max(maxRange, storagePrice)" :interval="maxRange / 100" />
                <Slider v-if="showBandwidthSlider" :value="bandwidthPrice" :disabled="existingHapp?.is_paused" @input="updateBandwidthPrice" :min="0" :max="Math.max(maxRange, bandwidthPrice)" :interval="maxRange / 100" />
              </div>
            </div>
            <table class='unit-chooser'>
              <tr><th /><th class='table-header'>Max price</th></tr>
              <tr>
                <td class='row-label'>
                  <input type='radio' id='cpu' value='cpu' v-model="priceUnit">
                  <label for='cpu' class='radio-label'>CPU</label>
                </td>
                <td>
                  <input type="text" v-model="cpuPrice" id='cpuMaxPrice' :disabled="existingHapp?.is_paused" class='price-value'/>
                </td>
                <td class='unit'>HF per hour</td>
              </tr>
              <tr class="hidden">
                <td class='row-label'>
                  <input type='radio' id='storage' value='storage' v-model="priceUnit">
                  <label for='storage' class='radio-label'>Storage</label>
                </td>
                <td>
                  <input type="text" v-model="storagePrice" id='storageMaxPrice' :disabled="existingHapp?.is_paused" class='price-value'/>
                </td>
                <td class='unit'>HF per GB</td>
              </tr>
              <tr>
                <td class='row-label'>
                  <input type='radio' id='bandwidth' value='bandwidth' v-model="priceUnit">
                  <label for='bandwidth' class='radio-label'>Data Transfer</label>
                </td>
                <td>
                  <input type="text" v-model="bandwidthPrice" id='bandwidthMaxPrice' :disabled="existingHapp?.is_paused" class='price-value'/>
                </td>
                <td class='unit'>HF per Gb</td>
              </tr>
            </table>
          </div>

          <div class='row total-estimated'>
            <span class='detail-label'>Total estimated cost per month:</span><span class='estimate'>{{perMonth.totalCost}} HF</span>
          </div>

          <div class='row estimation-warning'>
            <SmallCircledExclamationIcon class='bang-icon' color="#735CFE" />Estimated cost is not a guarantee or limit. Hosts will charge their individual rates which shall be equal or less than your max prices above based on actual usage.
          </div>

          <div v-if="hcErrorMessage" class='hc-error-message'>
            Oops! Something went wrong. <br /><br />{{ hcErrorMessage }}
            <ExIcon @click="clearHcErrorMessage" class='hc-error-close-button' :size="16" />
          </div>

          <div class='buttons'>
            <template v-if="existingHapp">
              <Button v-if="existingHapp.is_paused" class='save-draft' color='secondary' @click="handleUnpauseHapp">Unpause hApp</Button>
              <Button v-else class='save-draft' color='secondary' @click="handlePauseHapp">Pause hApp</Button>
            </template>
            <template v-else-if="existingDraft">
              <Button v-if="!isUpdatingDraft" class='save-draft' color='secondary' @click="handleUpdateDraft">Update Draft</Button>
              <Button v-if="isUpdatingDraft" class='save-draft' color='secondary'><Loader scale="0.5" /></Button>
            </template>
            <template v-else>
              <Button v-if="!isCreatingDraft" class='save-draft' color='secondary' @click="handleCreateDraft">Save as draft</Button>
              <Button v-if="isCreatingDraft" class='save-draft' color='secondary'><Loader scale="0.5" /></Button>
            </template>

            <Button v-if="!isDeploying"
              class='deploy'
              :disabled="cantDeploy"
              :color="deployButtonColor"
              @click="handleDeploy">
              {{ existingHapp ? "Deploy Changes" : "Deploy"}}
            </Button>
            <Button v-if="isDeploying" class='deploy' color='primary'><Loader scale="0.5" /></Button>
          </div>
          <div v-if="kycLevel < 2" class="kyc-error-message kyc-warning">
            <SmallCircledExclamationIcon class='bang-icon' color="#735CFE" />
            Please complete your level 2 KYC to deploy this hApp. You can still save as draft before completing your KYC
          </div>
          <div v-if="!holoport_pricing_and_jurisdictions || holoport_pricing_and_jurisdictions.length < 1" class="kyc-error-message kyc-warning">
            <SmallCircledExclamationIcon class='bang-icon' color="#735CFE" />
            No holoports are available to host your hApp at this time. Please save a draft and try again later.
          </div>          

          <div v-if="existingHapp" class='stop-hosting' @click="handleStopHosting">Stop hosting</div>

        </div>
      </div>
    </div>
  </PrimaryLayout>
</template>

<script>
import { mapState, mapActions, mapWritableState } from 'pinia'
import { useHolofuelStore, useUiStore, useHhaStore, useHoloStore } from 'src/stores'

import { pick } from 'lodash'
import PrimaryLayout from 'components/PrimaryLayout.vue'
import Button from '@uicommon/components/Button.vue'
import Loader from '@uicommon/components/Loader.vue'
import SmallQuestionIcon from '@uicommon/components/icons/SmallQuestionIcon.vue'
import SmallCircledExclamationIcon from '@uicommon/components/icons/SmallCircledExclamationIcon.vue'
import ExIcon from '@uicommon/components/icons/ExIcon.vue'
import DropDown from '@uicommon/components/Dropdown.vue'
import Chip from 'components/Chip.vue'
import Slider from 'components/Slider.vue'
import Ranges from 'components/Ranges.vue'
import { categoryList, jurisdictionList } from 'src/mockData'
import { isValidUrl, ensureProtocol, presentHostedUrl, JURISDICTION_STRATEGY_ENUM } from 'src/utils'
import { statsPerMonth } from 'src/utils/calculate'

export default {
  name: 'Dashboard',
  components: {
    PrimaryLayout,
    Button,
    SmallQuestionIcon,
    ExIcon,
    DropDown,
    Chip,
    Slider,
    Ranges,
    SmallCircledExclamationIcon,
    Loader
  },
  data () {
    return {
      displayPublisherName: true,
      showRegistrationInfoUrl: false,
      registrationInfoUrl: '',
      id: null,
      name: '',
      description: '',
      happFile: '',
      uid: '',
      hostedUrl: '',
      logoUrl: '',
      categories: [],
      excluded_jurisdictions: [],
      included_jurisdictions: [],
      jurisdiction_strategy: JURISDICTION_STRATEGY_ENUM.NOT_SELECTED,
      uniqueUsers: 1000,
      dailyUsers: 11000,
      priceUnit: '',
      cpuPrice: 0,
      storagePrice: 0,
      bandwidthPrice: 0,
      errors: {
        name: null,
        description: null,
        hostedUrl: null,
        logoUrl: null,
        registrationInfoUrl: null,
        happFile: null,
        includeOrExcludeJurisdiction: null
      },
      isCreatingDraft: false,
      isUpdatingDraft: false,
      isDeploying: false,
      jurisdictionInclusionOptions : ['-- Select --', 'exclusions', 'inclusions'],
      isPausing: false,
      isUnpausing: false
    }
  },
  mounted () {
    this.populateFormFromStore()
    this.priceUnit = 'cpu' // Fixes race condition where slider update wipes out existing data. Wait until data is loaded before activating the slider
  },
  methods: {
    ...mapActions(useHhaStore, ['happ','createDraft', 'updateDraft', 'deployHapp', 'createAndDeployDraft', 'updateHapp', 'pauseHapp', 'unpauseHapp', 'isUrlUnique']),
    ...mapActions(useUiStore, ['openHappDeployedModal', 'openConfirmPauseModal', 'openConfirmStopModal']),
    addCategory (categories) {
      this.categories = categories.sort();
    },
    onInclusionChange  (inclusionOption) {
      if( inclusionOption === this.jurisdictionInclusionOptions[0] ) {
        this.jurisdiction_strategy = JURISDICTION_STRATEGY_ENUM.NOT_SELECTED;
      }
      else {
        this.jurisdiction_strategy = (inclusionOption === this.jurisdictionInclusionOptions[1])
          ? JURISDICTION_STRATEGY_ENUM.EXCLUSIONS
          : JURISDICTION_STRATEGY_ENUM.INCLUSIONS;
      }
    },
    removeCategory (category) {
      this.categories = this.categories.filter(cat => category !== cat)
    },
    addJurisdiction (jurisdictions) {
      if( this.isJurisdictionStrategyExclude ) {
        this.excluded_jurisdictions = jurisdictions.sort();
      } else {
        this.included_jurisdictions = jurisdictions.sort();
      }
    },
    removeJurisdiction (jurisdiction) {
      if( this.isJurisdictionStrategyExclude ) {
        this.excluded_jurisdictions = this.excluded_jurisdictions.filter(juris => jurisdiction !== juris);
      } else {
        this.included_jurisdictions = this.included_jurisdictions.filter(juris => jurisdiction !== juris);
      }
    },
    updateUniqueUsers (val) {
      this.uniqueUsers = val
    },
    updateDailyUsers (val) {
      this.dailyUsers = val
    },
    updateCpuPrice (val) {
      this.cpuPrice = val
    },
    updateStoragePrice (val) {
      this.storagePrice = val
    },
    updateBandwidthPrice (val) {
      this.bandwidthPrice = val
    },
    async handleCreateDraft () {
      if (this.isCreatingDraft || this.isUpdatingDraft || this.isDeploying) return

      this.clearHcErrorMessage()

      if (!this.validateDraft()) {
        this.scrollToTop()
        return
      }
      this.isCreatingDraft = true
      try {
        await this.createDraft(this.createDraftPayload)
        this.$router.push('/dashboard')
        this.draftSavedModalVisible = true
        setTimeout(() => this.draftSavedModalVisible = false, 3_000)
      } catch (e) {
        this.hcErrorMessage = e.message
      } finally {
        this.isCreatingDraft = false
      }
    },
    async handleUpdateDraft () {
      if (this.isCreatingDraft || this.isUpdatingDraft || this.isDeploying) return

      if (!this.validateDraft()) {
        this.scrollToTop()
        return
      }
      this.isUpdatingDraft = true
      try {
        await this.updateDraft(this.updateHappPayload)
        this.$router.push('/dashboard')
        this.draftSavedModalVisible = true
        setTimeout(() => this.draftSavedModalVisible = false, 3_000)
      } catch (e) {
        this.hcErrorMessage = e.message
      } finally {
        this.isUpdatingDraft = false
      }
    },
    async handleDeploy () {
      if (this.isCreatingDraft || this.isUpdatingDraft || this.isDeploying) return

      this.clearHcErrorMessage()

      if (!this.validateDeploy()) {
        this.scrollToTop()
        return
      }

      this.isDeploying = true
      try {
        if (this.existingHapp) {
          const happ = await this.updateHapp(this.updateHappPayload)
          this.openHappDeployedModal(happ)
          this.$router.push('/dashboard')
        } else if (this.existingDraft) {
          // TODO: once we add updating, this will first need to update the draft in hha before deploying
          const happ = await this.deployHapp(this.existingDraft.id)
          this.openHappDeployedModal(happ)
          this.$router.push('/dashboard')
        } else {
          const happ = await this.createAndDeployDraft(this.createDraftPayload)
          this.openHappDeployedModal(happ)
          this.$router.push('/dashboard')
        }
      } catch (e) {
        this.hcErrorMessage = e.message
      } finally {
        this.isDeploying = false
      }
    },
    async handlePauseHapp () {
      if (this.pauseIsLoading || this.unpauseIsLoading) return

      this.clearHcErrorMessage()

      this.openConfirmPauseModal(this.existingData.id)
    },
    async handleUnpauseHapp () {
      if (this.pauseIsLoading || this.unpauseIsLoading) return

      this.clearHcErrorMessage()
      this.isUnpausing = true

      try {
        this.unpauseHapp(this.existingData.id)
        this.happUnpausedModalVisible = true
      } catch (e) {
        this.hcErrorMessage = e.message
      }
    },
    async handleStopHosting () {
      this.clearHcErrorMessage()

      this.openConfirmStopModal(this.existingData.id)
    },
    validateDraft () {
      let isValid = true

      if (!this.name) {
        this.errors.name = true
        isValid = false
      }
      if (this.happFile) {
        if (!this.happFile.endsWith('.happ')){
          this.errors.happFile = 'missing-happ'
          isValid = false
        }
      } else {
        this.errors.happFile = 'missing'
        isValid = false
      }

      if (this.hostedUrl && !isValidUrl(this.hostedUrl)) {
        this.errors.hostedUrl = 'invalid'
        isValid = false
      }

      if (this.hostedUrl && !this.isUrlUnique(this.hostedUrl, this.existingData?.id)) {
        this.errors.hostedUrl = 'duplicate'
        isValid = false
      }      

      if (this.logoUrl && !isValidUrl(this.logoUrl)) {
        this.errors.logoUrl = 'invalid'
        isValid = false
      }

      if (this.showRegistrationInfoUrl) {
        if (!this.registrationInfoUrl) {
          this.errors.registrationInfoUrl = 'missing'
          isValid = false
        } else if (!isValidUrl(this.registrationInfoUrl)) {
          this.errors.registrationInfoUrl = 'invalid'
          isValid = false
        }
      }

      if(this.jurisdiction_strategy === JURISDICTION_STRATEGY_ENUM.NOT_SELECTED) {
        this.errors.includeOrExcludeJurisdiction = 'missing'
        isValid = false;
      }
      else if (!this.isJurisdictionStrategyExclude && this.included_jurisdictions.length === 0) {
        this.errors.includeOrExcludeJurisdiction = 'invalid'
        isValid = false;
      }
      else {
        this.errors.includeOrExcludeJurisdiction = null;
      }

      return isValid
    },
    validateDeploy () {
      let isValid = this.validateDraft()

      if (!this.description) {
        this.errors.description = true
        isValid = false
      }

      return isValid
    },
    scrollToTop () {
      window.scrollTo({ top: 0 })
    },
    populateFormFromStore () {
      if (this.id) return // already populated

      if (this.existingData) {
        const {
          id,
          bundle_url,
          hosted_urls,
          name,
          description,
          logo_url,
          categories,
          jurisdictions,
          exclude_jurisdictions,
          uid,
          publisher_pricing_pref: {
            cpu,
            storage,
            bandwidth
          },
          login_config: {
            display_publisher_name,
            registration_info_url,
          }
        } = this.existingData

        this.id = id
        this.happFile = bundle_url
        this.hostedUrl = (hosted_urls?.length > 0) ? ensureProtocol(hosted_urls[0]) : null
        this.name = name
        this.description = description
        this.logoUrl = logo_url
        this.categories = categories
        this.excluded_jurisdictions = (exclude_jurisdictions ) ? jurisdictions : []
        this.included_jurisdictions = (exclude_jurisdictions ) ? [] : jurisdictions
        this.jurisdiction_strategy =
          (exclude_jurisdictions )
            ? JURISDICTION_STRATEGY_ENUM.EXCLUSIONS
            : JURISDICTION_STRATEGY_ENUM.INCLUSIONS
        this.uid = uid
        this.cpuPrice = Number(cpu)
        this.storagePrice = Number(storage)
        this.bandwidthPrice = Number(bandwidth)
        this.displayPublisherName = display_publisher_name
        this.showRegistrationInfoUrl = !!registration_info_url
        this.registrationInfoUrl = registration_info_url
      }
    },
    clearHcErrorMessage () {
      this.hcErrorMessage = null
    },
  },
  computed: {
    ...mapWritableState(useUiStore, ['draftSavedModalVisible', 'happUnpausedModalVisible', 'hcErrorMessage']),
    ...mapState(useHhaStore, ['happs', 'getMyHappsIsLoading', 'pauseIsLoading', 'unpauseIsLoading', 'holoport_pricing_and_jurisdictions']),
    ...mapState(useHolofuelStore, { happManagerName: 'nickname', } ),
    ...mapState(useHoloStore, ['agentKycLevel']),
    kycLevel () {
      return this.agentKycLevel ? this.agentKycLevel : 1
    },
    existingDraft () {
      // if we're editing a draft, this returns that draft
      const { id } = this.$route.params

      const happ = (id) ? this.happ(id) : null
      return (happ && happ.is_draft) ? happ : null
    },
    existingHapp () {
      // if we're editing an already deployed happ, this returns that happ
      const { id } = this.$route.params

      const happ = (id) ? this.happ(id) : null
      return (happ && !happ.is_draft) ? happ : null
    },
    existingData () {
      return this.existingHapp || this.existingDraft
    },
    createDraftPayload () {
      const payload = {
        name: this.name,
        description: this.description,
        bundle_url: this.happFile.trim(),
        ui_src_url: '',
        dnas: [],
        hosted_urls: this.hostedUrl ? [presentHostedUrl(this.hostedUrl)] : [],
        logo_url: this.logoUrl.trim(),
        categories: [...this.categories],
        jurisdictions: (this.isJurisdictionStrategyExclude)
          ? [...this.excluded_jurisdictions] 
          : [...this.included_jurisdictions],
        exclude_jurisdictions: (this.isJurisdictionStrategyExclude),
        uid: this.uid?.trim() === "" ? null : this.uid?.trim(),
        publisher_pricing_pref: {
          cpu: this.cpuPrice.toString(),
          storage: this.storagePrice.toString(),
          bandwidth: this.bandwidthPrice.toString()
        },
        login_config: {
          require_joining_code: this.requireJoiningCode,
          display_publisher_name: this.displayPublisherName,
          registration_info_url: this.showRegistrationInfoUrl ? this.registrationInfoUrl : ''
        }
      }
      return payload
    },
    isJurisdictionStrategyExclude () {
      return this.jurisdiction_strategy === JURISDICTION_STRATEGY_ENUM.EXCLUSIONS
    },
    updateHappPayload () {
      return pick({
        id: this.existingData.id,
        ...this.createDraftPayload
      }, ['id', 'name', 'description', 'hosted_urls', 'logo_url', 'categories', 'jurisdictions', 'exclude_jurisdictions', 'uid', 'publisher_pricing_pref', 'login_config'])
    },
    pageTitle () {
      return this.existingData
        ? this.name
        : "Add a hApp"
    },
    happStatus () {
      return this.existingHapp
        ? this.existingHapp.is_paused
          ? 'Paused'
          : 'Deployed'
        : this.existingDraft
          ? 'Draft'
          : null
    },
    showCpuSlider () {
      return this.priceUnit === 'cpu'
    },
    showStorageSlider () {
      return this.priceUnit === 'storage'
    },
    showBandwidthSlider () {
      return this.priceUnit === 'bandwidth'
    },
    pricing_filtered_by_jurisdictions () {
      if( this.jurisdiction_strategy === JURISDICTION_STRATEGY_ENUM.NOT_SELECTED ) {
          return this.holoport_pricing_and_jurisdictions
      }

      return this.holoport_pricing_and_jurisdictions.filter((pricing_and_jurisdiction) => {
        if( this.jurisdiction_strategy === JURISDICTION_STRATEGY_ENUM.EXCLUSIONS ) {
          return this.excluded_jurisdictions.includes(pricing_and_jurisdiction.jurisdiction) === false
        } else {
          return this.included_jurisdictions.includes(pricing_and_jurisdiction.jurisdiction) === true
        }
      })
    },
    rangesData () {
      if (this.showCpuSlider) {
        return this.pricing_filtered_by_jurisdictions.map(pricing => {
          return pricing.price_compute
        })
      } else if (this.showStorageSlider) {
        return this.pricing_filtered_by_jurisdictions.map(pricing => {
          return pricing.price_storage
        })
      } else {
        return this.pricing_filtered_by_jurisdictions.map(pricing => {
          return pricing.price_bandwidth
        })
      }
    },
    maxRange () {
      return Math.max(...this.rangesData, 1)
    },
    priceSliderValue () {
      if (this.showCpuSlider) {
        return this.cpuPrice
      } else if (this.showStorageSlider) {
        return this.storagePrice
      } else {
        return this.bandwidthPrice
      }
    },
    happFileErrorMessage () {
      if (this.errors.happFile === 'missing') {
        return 'Please enter a happ file that ends with .happ'
      }
      if (this.errors.happFile === 'missing-happ') {
        return 'Please enter a happ file that ends with .happ'
      } else {
        return `Please enter a valid hApp URL (it should start with "http" and end with .happ)`
      }
    },
    hostedUrlErrorMessage () {
      return `Please enter a valid URL`
    },
    hostedUrlNotUniqueErrorMessage () {
      return `You have already registered a hApp with this URL.`
    },    
    nameErrorMessage () {
      return `Please enter a name for your hApp`
    },
    descriptionErrorMessage () {
      return `Please enter a description for your hApp`
    },
    logoUrlErrorMessage () {
      if (this.errors.logoUrl === 'missing') {
        return 'Please enter a logo URL for your hApp'
      } else {
        return `Please enter a valid logo URL (it should start with "http")`
      }
    },
    registrationInfoUrlErrorMessage () {
      if (this.errors.registrationInfoUrl === 'missing') {
        return 'Please enter a help url for your hApp (or uncheck the "Display help URL" option above)'
      } else {
        return `Please enter a valid help url (it should start with "http")`
      }
    },
    jurisdictionErrorMessage () {
      if (this.errors.includeOrExcludeJurisdiction === 'missing') {
        return 'Please select if jurisdictions should be included or excluded.'
      } else {
        return 'You have selected that juridictions should be included but did not specify any jurisdictions. Please select at least one jurisdiction.'
      }
    },
    categoryList () {
      return categoryList.sort()
    },
    jurisdictionList () {
      return jurisdictionList.sort()
    },
    selectedJurisdictionInclusionOption () {
      if( this.jurisdiction_strategy === JURISDICTION_STRATEGY_ENUM.NOT_SELECTED ) {
        return this.jurisdictionInclusionOptions[0];
      }

      return (this.jurisdiction_strategy === JURISDICTION_STRATEGY_ENUM.EXCLUSIONS)
        ? this.jurisdictionInclusionOptions[1]
        : this.jurisdictionInclusionOptions[2];
    },
    perMonth () {
      const [cpu, storage, bandwidth, totalCost] = statsPerMonth(
        this.uniqueUsers,
        this.dailyUsers,
        this.cpuPrice,
        this.storagePrice,
        this.bandwidthPrice
      )
      return {
        cpu: Number(cpu).toLocaleString(undefined, { maximumFractionDigits: 5 }), // undefined defaults to browser default language
        storage: Number(storage).toLocaleString(undefined, { maximumFractionDigits: 5 }),
        bandwidth: Number(bandwidth).toLocaleString(undefined, { maximumFractionDigits: 5 }),
        totalCost: Number(totalCost).toLocaleString(undefined, { maximumFractionDigits: 0 }),
      }
    },
    deployButtonColor () {
      return this.cantDeploy ? 'disabled' : 'primary'
    },
    cantDeploy() {
      return (this.existingHapp?.is_paused ||
        this.kycLevel < 2 ||
        (!this.holoport_pricing_and_jurisdictions ||
        this.holoport_pricing_and_jurisdictions.length < 1) &&
        process.env.VUE_APP_ENV !== 'local-no-backend')
    }
  },
  watch: {
    name () {
      this.errors.name = false
    },
    description () {
      this.errors.description = false
    },
    happFile () {
      this.errors.happFile = false
    },
    hostedUrl () {
      this.errors.hostedUrl = false
    },
    logoUrl () {
      this.errors.logoUrl = false
    },
    showRegistrationInfoUrl () {
      this.errors.registrationInfoUrl = false
    },
    registrationInfoUrl () {
      this.errors.registrationInfoUrl = false
    },
    happs () {
      this.populateFormFromStore()
    },
    cpuPrice () {
      if (this.priceUnit !== 'cpu') {
        this.priceUnit = 'cpu'
      }
    },
    storagePrice () {
      if (this.priceUnit !== 'storage') {
        this.priceUnit = 'storage'
      }
    },
    bandwidthPrice () {
      if (this.priceUnit !== 'bandwidth') {
        this.priceUnit = 'bandwidth'
      }
    },
  }
}

</script>

<style scoped>
.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
h2 {
  margin: 0 0 30px;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: black;
  display: flex;
}

.happ-status {
  margin-left: 10px;
  border-radius: 100px;
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  max-height: 2rem;
}
.Draft-status {
  color: #FFA800;
  background-color: rgba(255, 235, 166, 0.72);
}
.Deployed-status {
  color: #14BE72;
  background-color: #CBFFE3;
}
.Paused-status {
  color: #FD0D0D;
  background-color: rgba(255, 203, 203, 0.47);
}

h3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin: 0 0 18px 0;
  color: black;
}
.form {
  padding: 25px 252px;
  box-shadow: 0px 4px 20px #ECEEF1;
  border-radius: 5px;
  background-color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #606C8B;
}
.row {
  display: flex;
  align-items: center;
}
.column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.happ-details-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #313C59;
}
.item {
  margin-bottom: 32px;
}
.detail-label {
  font-weight: bold;
  margin-right: 5px;
}
.item-content {
  padding-left: 16px;
}
.question-icon {
  margin-left: 5px;
}
input[type="text"] {
  border-radius: 5px;
  border: 1px solid #606C8B;
  padding: 8px;
}
.happ-setup label {
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 8px;
}
.instructions-link {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #606C8B;
  margin-bottom: 25px;
}
input[type="radio"] {
  color: #313C59;
  margin-right: 14px;
}
.radio-label {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #606C8B;
}
.happ-display {
  margin-top: 5px;
  margin-bottom: 5px;
}
.happ-display-label {
  font-weight: bold;
  color: #606C8B;
  width: 110px;
}
.happ-display input {
  flex-grow: 1;
}
.happ-display input.error {
  border-color: #FF5555;
}
.error-row {
  margin: 5px 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #FF5555;
}
.error-row .icon {
  margin-right: 10px;
}
.happ-display-section .error-row {
  margin-left: 100px;
}

.logo-preview {
  width: 86px;
  height: 86px;
  border: 1px solid #606C8B;
}
.login-configuration {
  margin-top: 11px;
}
#registrationInfoUrl {
  margin-top: 10px;
}
#registrationInfoUrl.error {
  border-color: #FF5555;
}
input[type="checkbox"] {
  color: #313C59;
  margin-right: 10px;
}
#domain {
  margin-top: 10px;
  padding: 8px;
  margin-bottom: 10px;
}
.pricing-card {
  margin-top: 28px;
  padding: 30px;
  background: rgba(199, 211, 255, 0.32);
  border: 1px solid #606C8B;
  border-radius: 10px;
}
.slider-row {
  margin-bottom: 50px
}
.pricing-label {
  font-weight: bold;
  color: #000000;
}
.slider-row .pricing-label {
  width: 190px;
}
.slider-label {
  margin: 0px 14px;
  color: #000000;
}
.estimated-usage {
  position: relative;
  margin-top: 78px;
  border-spacing: 0 6px;
}
.estimated-usage .table-header {
  font-weight: bold;
  color: #606C8B;
  position: absolute;
  top: -10px;
  white-space: nowrap;
}
.estimated-usage .row-label {
  font-weight: bold;
  padding-right: 66px
}
.estimated-usage .unit {
  padding-left: 10px
}
.price-chooser {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}
.ranges-column {
  margin-right: 20px;
  max-width: 450px;
  min-width: 100px;
}
.ranges {
  margin-top: 20px;
  margin-bottom: -7px;
}
.slider-wrapper {

}
.unit-chooser {
  position: relative;
  border-spacing: 0 6px;
}
.unit-chooser .table-header {
  font-weight: bold;
  color: #606C8B;
  position: absolute;
  top: -10px;
  white-space: nowrap;
}
.unit-chooser label {
  font-weight: bold;
  color: #313C59;
  padding-right: 20px
}
.unit-chooser .unit {
  padding-left: 10px
}
.unit-chooser .price-value {
  width: 75px;
}
.total-estimated {
  margin-top: 40px;
}
.estimate {
  font-weight: bold;
  color: #606C8B;
  margin-left: 2px;
}
.estimation-warning {
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 62px;
  font-style: italic;
  font-weight: 600;
  color: #313C59;
}
.bang-icon {
  flex-basis: 26px;
  margin-right: 7px;
  margin-top: 2px;
}
.temporary-domain-label {
  white-space: nowrap;
  font-weight: bold;
  color: #606C8B;
  margin: 10px 0;
}
.temporary-domain {
  white-space: nowrap;
  font-size: 14px;
  color: #313C59;
  margin-bottom: 20px;
}
#hostedUrl {
  flex-grow: 1;
  margin-bottom: 10px;
}
.buttons {
  display: flex;
  margin-bottom: 24px;
}
.save-draft {
  width: 146px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin: 0 16px 0 0;
}
.deploy {
  width: 146px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
.hc-error-message {
  position: relative;
  border: 1px solid #FF5555;
  background-color: rgba(255, 95, 95, 0.15);
  border-radius: 4px;
  padding: 20px;
  margin-top: -28px;
  margin-bottom: 26px;
}
.hc-error-close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.chip-row {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
}

.chip-row-label {
  font-weight: bold;
  color: #606C8B;
  flex: 0 0 6rem;
  margin-top: 0.5rem;
}

.chip-row-dropdown {
  flex: 0 0 6rem;
  margin-top: 0.5rem;
  max-height: 2.1rem;
  height: 2.1rem;
}

.chip-row-chips {
  flex: 0 1 auto;
  width: 100%;
}

.stop-hosting {
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.kyc-error-message {
  max-width: 515px;
  border-radius: 5px;
  background: #EDF1FF;
  padding: 16px 40px;
  display: flex;
  align-items: center;
}

.kyc-warning {
  align-items: flex-start;
  margin-top: 15px;
  font-weight: 600;
  color: #313C59;
}

.hidden {
  display: none;
}

@media screen and (max-width: 1050px) {

}
</style>
