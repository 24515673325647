<template>
  <Modal :handleClose="handleClose" modalClass='confirm-pay-selected-modal'>
    <CircledExclamationIcon />
    <div class='content'>
      <h1 class="title">Please Confirm Payment</h1>
      <p>
        You have selected {{ numInvoicesText }}.<br />
        Please confirm that you would like to pay {{ invoiceNounPhrase }} now.
      </p>
      <p>
        <span class="amount-label">Total Amount Payable:</span> <span class="amount">{{ totalAmount }} HF</span>
      </p>
    </div>
    <div class='buttons'>
      <Button class='edit' color='secondary' @click="handleClose">Cancel</Button>
      <Button class='close' color='primary' @click="confirm">Yes, Pay Now</Button>
    </div>
  </Modal>
</template>

<script>
import { mapState, mapActions, mapWritableState } from 'pinia'
import { useUiStore, useHolofuelStore } from 'src/stores'

import Modal from '@uicommon/components/Modal'
import Button from '@uicommon/components/Button.vue'
import CircledExclamationIcon from '@uicommon/components/icons/CircledExclamationIcon'
import { presentHolofuelAmount } from 'src/utils'

export default {
  name: 'ConfirmPaySelectedModal',
  components: {
    Modal,
    Button,
    CircledExclamationIcon  
  },
  props: {
    handleClose: {
      type: Function,
      required: true
    }
  },
  methods: {
    ...mapActions(useHolofuelStore, ['bulkPayInvoices']),
    confirm () {
      try {
        this.inProcessInvoiceIds = this.selectedInvoices.map(si => si.id)
        this.bulkPayInvoices(this.selectedInvoices)
        this.handleClose()
        this.processingPaymentModalVisible = true
      } catch (e) {
        this.hcErrorMessage = e.message
        this.handleClose()
      }
    },
    isSelected (invoiceId) {
      return !!this.selectedInvoiceIds[invoiceId]
    },
  },
  computed: {
    ...mapWritableState(useUiStore, ['processingPaymentModalVisible', 'hcErrorMessage', 'inProcessInvoiceIds']),
    ...mapState(useHolofuelStore, ['actionableHostingInvoices']),
    ...mapState(useUiStore, ['selectedInvoiceIds']),
    selectedInvoices () {
      return this.actionableHostingInvoices.filter(invoice => this.isSelected(invoice.id))
    },
    numInvoicesText () {
      const numInvoices = this.selectedInvoices.length
      return numInvoices === 1 ? "1 invoice" : `${numInvoices} invoices`
    },
    invoiceNounPhrase () {
      return this.selectedInvoices.length === 1 ? "this invoice" : "these invoices"
    },
    totalAmount () {
      return presentHolofuelAmount(String(this.selectedInvoices.map(invoice => invoice.amount).reduce((total, invoiceAmount) => total + Number(invoiceAmount), 0)))
    }
  }
}
</script>

<style>
.confirm-pay-selected-modal.modal {
  max-width: 675px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
}
</style>

<style scoped>
.content {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #313C59;
  padding: 0 74px;
}
.title {
  font-family: Nunito Sans;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}
.amount-label {
  color: #7482A6;
}
.amount {
  font-weight: 700;
  color: #313C59;
}
.buttons {
  display: flex;
  margin: 16px 0;
}
.buttons .edit {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}
.buttons .close {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}

</style>
