<template>
  <Modal :handleClose="handleClose" modalClass='deployed-happ-modal'>
    <CircledCheckIcon />
    <div class="spinner" style="spinner" v-if="isLoading">
      <CircleSpinner  />
    </div>
    <div class='content' v-if="!isLoading">
      <p>
        Congratulations! Your hApp has been deployed to the Holo Network!
      </p>
      <p v-if="!urlValidated" class="need-to-validate-message">
        Before Holo can route Internet traffic to your hApp you must verify you own this domain. Kindly follow the steps below.
      </p>
      <div v-if="!urlValidated" class="dns-instructions-container">
        <p class="dns-step-label">Step 1</p>
        <p class="dns-step">Open a new tab or window in your browser and sign into the website where your domain is registered.</p>
        <p class="dns-step-label">Step 2</p>
        <p class="dns-step">Find where you manage your DNS settings (e.g. DNS Management, Control Panel, cPanel, etc.)</p>
        <p class="dns-step-label">Step 3</p>
        <p class="dns-step">Add a new TXT record for the domain or sub-domain, paste in the TXT Value below, and save. </p>
        <p class="dns-info">Verification can take up to 30 minutes. If it takes longer, check that you have input the TXT Value correctly.</p>
      </div>
      <div v-if="!urlValidated" class="domain-info">
        <div class="domain-values">
          <div class="domain-label">TXT Value:</div>
          <div class="domain-value">{{txtRecordPrefix}}={{ lastDeployedHapp.id }}</div>
          <div class="copy-icon" @click="onCopyTXTRecordToClipboard">
            <CopyIcon />
          </div>
        </div>
        <div class="domain-values">
          <div class="domain-label">URL of hosted UI:</div>
          <div class="domain-value">{{ hostedUrl }}</div>
        </div>
      </div>
      <div v-if="!urlValidated" class="txt-info-container">
        <BangIcon />
        For future reference you can find your TXT Value on the Individual hApp detail view of any deployed hApp.
      </div>
      <p v-if="urlValidated" class="deployment-note">
        Please note it may take a few hours to complete the deployment of your hApp.
      </p>
    </div>
    <div class='buttons'>
      <Button class='edit' color='secondary' @click="goToEdit">Edit hApp</Button>
      <Button class='close' color='primary' @click="goToDashboard">Close</Button>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'pinia'
import { useUiStore } from 'src/stores'
import Modal from '@uicommon/components/Modal'
import Button from '@uicommon/components/Button'
import CircledCheckIcon from '@uicommon/components/icons/CircledCheckIcon'
import CircleSpinner from '@uicommon/components/CircleSpinner'
import CopyIcon from '@uicommon/components/icons/CopyIcon'
import BangIcon from '@uicommon/components/icons/BangIcon.vue'
import { ensureProtocol, verifyDnsTxtRecord, kTxtPrefix } from 'src/utils'
import { copyToClipboard } from '@uicommon/utils/clipboardUtils'

export default {
  name: 'HappDeployedModal',
  components: {
    Modal,
    BangIcon,
    Button,
    CircledCheckIcon,
    CircleSpinner,
    CopyIcon
  },
  props: {
    handleClose: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      urlValidated: true,
      isLoading: false,
      txtRecordPrefix: kTxtPrefix
    }
  },
  async created() {
    this.isLoading = true
    try {
      await this.urlValidate()
    } finally {
      this.isLoading = false
    }

    this.isLoading = false
  },
  methods: {
    goToEdit () {
      this.$router.push({ path: `/happ/${this.lastDeployedHapp.id}`})
      this.handleClose()
    },
    goToDashboard () {
      this.handleClose()
      this.$router.push('/dashboard')
    },
    async urlValidate () {
      if( !this.hostedUrl || this.hostedUrl.length === 0) {
        return true
      } else {
        const txt_record_validator = `${kTxtPrefix}=${this.lastDeployedHapp.id}`
        this.urlValidated = await verifyDnsTxtRecord(this.hostedUrl, txt_record_validator)
      }
    },
    onCopyTXTRecordToClipboard () {
      copyToClipboard(`${this.txtRecordPrefix}=${this.lastDeployedHapp.id}`)
    }
  },
  computed: {
    ...mapState(useUiStore, ['lastDeployedHapp']),
    hostedUrl () {
      if (this.lastDeployedHapp?.hosted_urls?.length > 0) {
        return ensureProtocol(this.lastDeployedHapp.hosted_urls[0])
      } else {
        return null
      }
    },
  }
}
</script>

<style>
.deployed-happ-modal.modal {
  max-width: 675px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
}
</style>

<style scoped>
.content {
  color: #313C59;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  padding: 0 74px;
}

.need-to-validate-message {
  color: #313C59;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  margin-top: 32px;
}

.dns-instructions-container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #EDF1FF;
  color: #606C8B;
  font-style: normal;
  padding: 16px 24px;
  width: 100%;
  text-align: left;
}

.dns-step-label {
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  margin: 8px 0px 4px 0px
}

.dns-step {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
  margin: 0px 0px 8px 0px;
}

.dns-info {
font-size: 12px;
font-weight: 700;
line-height: 140%; /* 16.8px */  
}
.hosted-url {
  font-weight: 400;
  color: #313C59;
  text-decoration: none;
}
.buttons {
  display: flex;
  margin: 16px 0;
}
.buttons .edit {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}

.spinner {
  height: 500px;
  width: 500px;
}
.buttons .close {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}

/* div with 2 columns left column right aligned, right column left aligned and values are centered */
.domain-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;
  padding-bottom: 16px;
}

.domain-values {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.domain-label {
  width: 35%;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  padding-right: 16px;
}

.domain-value {
  width: 65%;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  word-break: break-all;
  padding-right: 32px;
}

.copy-icon {
  margin-left: 8px;
  width: 5%;
  cursor: pointer;
  opacity: 0.3;
}

.txt-info-container {
  border-radius: 8px;
  background: #F7F6FE;  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3px;
  padding: 8px 16px;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;

  color: #313C59;
  font-family: Nunito Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 17.6px */  
}

.deployment-note {
  color: #313C59;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
